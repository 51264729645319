const countriesList = [
  {
    countryid: '1',
    name: 'AFGHANISTAN',
    iso_code: 'AF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '2',
    name: 'ALAND ISLANDS',
    iso_code: 'AX',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '3',
    name: 'ALBANIA',
    iso_code: 'AL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '4',
    name: 'ALGERIA',
    iso_code: 'DZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '5',
    name: 'AMERICAN SAMOA',
    iso_code: 'AS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '6',
    name: 'ANDORRA',
    iso_code: 'AD',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '7',
    name: 'ANGOLA',
    iso_code: 'AO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '8',
    name: 'ANGUILLA',
    iso_code: 'AI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '9',
    name: 'ANTARCTICA',
    iso_code: 'AQ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '10',
    name: 'ANTIGUA AND BARBUDA',
    iso_code: 'AG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '11',
    name: 'ARGENTINA',
    iso_code: 'AR',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info:
      '<p>For your delivery address, please enter your CPA code in the Zip/Post code box.</p>'
  },
  {
    countryid: '12',
    name: 'ARMENIA',
    iso_code: 'AM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '13',
    name: 'ARUBA',
    iso_code: 'AW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '14',
    name: 'AUSTRALIA',
    iso_code: 'AU',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '15',
    name: 'AUSTRIA',
    iso_code: 'AT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '16',
    name: 'AZERBAIJAN',
    iso_code: 'AZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '17',
    name: 'BAHAMAS',
    iso_code: 'BS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '18',
    name: 'BAHRAIN',
    iso_code: 'BH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '19',
    name: 'BANGLADESH',
    iso_code: 'BD',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '20',
    name: 'BARBADOS',
    iso_code: 'BB',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '21',
    name: 'BELARUS',
    iso_code: 'BY',
    shipping_excluded: '1',
    display_po_box_info: '0',
    billing_excluded: '1',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '22',
    name: 'BELGIUM',
    iso_code: 'BE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '23',
    name: 'BELIZE',
    iso_code: 'BZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '24',
    name: 'BENIN',
    iso_code: 'BJ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '25',
    name: 'BERMUDA',
    iso_code: 'BM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '26',
    name: 'BHUTAN',
    iso_code: 'BT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '27',
    name: 'BOLIVIA, PLURINATIONAL STATE OF',
    iso_code: 'BO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '28',
    name: 'BONAIRE, SINT EUSTATIUS AND SABA',
    iso_code: 'BQ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '29',
    name: 'BOSNIA AND HERZEGOVINA',
    iso_code: 'BA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '30',
    name: 'BOTSWANA',
    iso_code: 'BW',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '31',
    name: 'BOUVET ISLAND',
    iso_code: 'BV',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '32',
    name: 'BRAZIL',
    iso_code: 'BR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '33',
    name: 'BRITISH INDIAN OCEAN TERRITORY',
    iso_code: 'IO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '34',
    name: 'BRUNEI DARUSSALAM',
    iso_code: 'BN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '35',
    name: 'BULGARIA',
    iso_code: 'BG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '36',
    name: 'BURKINA FASO',
    iso_code: 'BF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '37',
    name: 'BURUNDI',
    iso_code: 'BI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '38',
    name: 'CAMBODIA',
    iso_code: 'KH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '39',
    name: 'CAMEROON',
    iso_code: 'CM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '40',
    name: 'CANADA',
    iso_code: 'CA',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'USD',
    allow_print_inspection_copy: '0',
    is_usd_region: '1',
    is_usd_domestic: '0',
    po_box_info:
      '<p>Please provide a phone number that you are contactable on and include the full area code.</p>'
  },
  {
    countryid: '41',
    name: 'CAPE VERDE',
    iso_code: 'CV',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '42',
    name: 'CAYMAN ISLANDS',
    iso_code: 'KY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '43',
    name: 'CENTRAL AFRICAN REPUBLIC',
    iso_code: 'CF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '44',
    name: 'CHAD',
    iso_code: 'TD',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '45',
    name: 'CHILE',
    iso_code: 'CL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '46',
    name: 'CHINA',
    iso_code: 'CN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '47',
    name: 'CHRISTMAS ISLAND',
    iso_code: 'CX',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '48',
    name: 'COCOS (KEELING) ISLANDS',
    iso_code: 'CC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '49',
    name: 'COLOMBIA',
    iso_code: 'CO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '50',
    name: 'COMOROS',
    iso_code: 'KM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '51',
    name: 'CONGO',
    iso_code: 'CG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '52',
    name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
    iso_code: 'CD',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '53',
    name: 'COOK ISLANDS',
    iso_code: 'CK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '54',
    name: 'COSTA RICA',
    iso_code: 'CR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '55',
    name: "COTE D'IVOIRE",
    iso_code: 'CI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '56',
    name: 'CROATIA',
    iso_code: 'HR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '57',
    name: 'CUBA',
    iso_code: 'CU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '58',
    name: 'CURACAO',
    iso_code: 'CW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '59',
    name: 'CYPRUS',
    iso_code: 'CY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '60',
    name: 'CZECH REPUBLIC',
    iso_code: 'CZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '61',
    name: 'DENMARK',
    iso_code: 'DK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '62',
    name: 'DJIBOUTI',
    iso_code: 'DJ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '63',
    name: 'DOMINICA',
    iso_code: 'DM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '64',
    name: 'DOMINICAN REPUBLIC',
    iso_code: 'DO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '65',
    name: 'ECUADOR',
    iso_code: 'EC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '66',
    name: 'EGYPT',
    iso_code: 'EG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '67',
    name: 'EL SALVADOR',
    iso_code: 'SV',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '68',
    name: 'EQUATORIAL GUINEA',
    iso_code: 'GQ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '69',
    name: 'ERITREA',
    iso_code: 'ER',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '70',
    name: 'ESTONIA',
    iso_code: 'EE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '213',
    name: 'ESWATINI',
    iso_code: 'SZ',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '71',
    name: 'ETHIOPIA',
    iso_code: 'ET',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '72',
    name: 'FALKLAND ISLANDS (MALVINAS)',
    iso_code: 'FK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '73',
    name: 'FAROE ISLANDS',
    iso_code: 'FO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '74',
    name: 'FIJI',
    iso_code: 'FJ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '75',
    name: 'FINLAND',
    iso_code: 'FI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '76',
    name: 'FRANCE',
    iso_code: 'FR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '77',
    name: 'FRENCH GUIANA',
    iso_code: 'GF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '78',
    name: 'FRENCH POLYNESIA',
    iso_code: 'PF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '79',
    name: 'FRENCH SOUTHERN TERRITORIES',
    iso_code: 'TF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '80',
    name: 'GABON',
    iso_code: 'GA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '81',
    name: 'GAMBIA',
    iso_code: 'GM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '82',
    name: 'GEORGIA',
    iso_code: 'GE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '83',
    name: 'GERMANY',
    iso_code: 'DE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '84',
    name: 'GHANA',
    iso_code: 'GH',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '85',
    name: 'GIBRALTAR',
    iso_code: 'GI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '86',
    name: 'GREECE',
    iso_code: 'GR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '87',
    name: 'GREENLAND',
    iso_code: 'GL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '88',
    name: 'GRENADA',
    iso_code: 'GD',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '89',
    name: 'GUADELOUPE',
    iso_code: 'GP',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '90',
    name: 'GUAM',
    iso_code: 'GU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '91',
    name: 'GUATEMALA',
    iso_code: 'GT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '92',
    name: 'GUERNSEY',
    iso_code: 'GG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '93',
    name: 'GUINEA',
    iso_code: 'GN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '94',
    name: 'GUINEA-BISSAU',
    iso_code: 'GW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '95',
    name: 'GUYANA',
    iso_code: 'GY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '96',
    name: 'HAITI',
    iso_code: 'HT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '97',
    name: 'HEARD ISLAND AND MCDONALD ISLANDS',
    iso_code: 'HM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '98',
    name: 'HOLY SEE (VATICAN CITY STATE)',
    iso_code: 'VA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '99',
    name: 'HONDURAS',
    iso_code: 'HN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '100',
    name: 'HONG KONG',
    iso_code: 'HK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '101',
    name: 'HUNGARY',
    iso_code: 'HU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '102',
    name: 'ICELAND',
    iso_code: 'IS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '103',
    name: 'INDIA',
    iso_code: 'IN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '104',
    name: 'INDONESIA',
    iso_code: 'ID',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '105',
    name: 'IRAN, ISLAMIC REPUBLIC OF',
    iso_code: 'IR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '106',
    name: 'IRAQ',
    iso_code: 'IQ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '107',
    name: 'IRELAND',
    iso_code: 'IE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '108',
    name: 'ISLE OF MAN',
    iso_code: 'IM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '109',
    name: 'ISRAEL',
    iso_code: 'IL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '110',
    name: 'ITALY',
    iso_code: 'IT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '111',
    name: 'JAMAICA',
    iso_code: 'JM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '112',
    name: 'JAPAN',
    iso_code: 'JP',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '113',
    name: 'JERSEY',
    iso_code: 'JE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '114',
    name: 'JORDAN',
    iso_code: 'JO',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '115',
    name: 'KAZAKHSTAN',
    iso_code: 'KZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '116',
    name: 'KENYA',
    iso_code: 'KE',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '117',
    name: 'KIRIBATI',
    iso_code: 'KI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '118',
    name: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    iso_code: 'KP',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '119',
    name: 'KOREA, REPUBLIC OF',
    iso_code: 'KR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '120',
    name: 'KUWAIT',
    iso_code: 'KW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '121',
    name: 'KYRGYZSTAN',
    iso_code: 'KG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '122',
    name: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    iso_code: 'LA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '123',
    name: 'LATVIA',
    iso_code: 'LV',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '124',
    name: 'LEBANON',
    iso_code: 'LB',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '125',
    name: 'LESOTHO',
    iso_code: 'LS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '126',
    name: 'LIBERIA',
    iso_code: 'LR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '127',
    name: 'LIBYAN ARAB JAMAHIRIYA',
    iso_code: 'LY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '128',
    name: 'LIECHTENSTEIN',
    iso_code: 'LI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '129',
    name: 'LITHUANIA',
    iso_code: 'LT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '130',
    name: 'LUXEMBOURG',
    iso_code: 'LU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '131',
    name: 'MACAO',
    iso_code: 'MO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '133',
    name: 'MADAGASCAR',
    iso_code: 'MG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '134',
    name: 'MALAWI',
    iso_code: 'MW',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '135',
    name: 'MALAYSIA',
    iso_code: 'MY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '136',
    name: 'MALDIVES',
    iso_code: 'MV',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '137',
    name: 'MALI',
    iso_code: 'ML',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '138',
    name: 'MALTA',
    iso_code: 'MT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '139',
    name: 'MARSHALL ISLANDS',
    iso_code: 'MH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '140',
    name: 'MARTINIQUE',
    iso_code: 'MQ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '141',
    name: 'MAURITANIA',
    iso_code: 'MR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '142',
    name: 'MAURITIUS',
    iso_code: 'MU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '143',
    name: 'MAYOTTE',
    iso_code: 'YT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '144',
    name: 'MEXICO',
    iso_code: 'MX',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '145',
    name: 'MICRONESIA, FEDERATED STATES OF',
    iso_code: 'FM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '146',
    name: 'MOLDOVA, REPUBLIC OF',
    iso_code: 'MD',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '147',
    name: 'MONACO',
    iso_code: 'MC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '148',
    name: 'MONGOLIA',
    iso_code: 'MN',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '149',
    name: 'MONTENEGRO',
    iso_code: 'ME',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '150',
    name: 'MONTSERRAT',
    iso_code: 'MS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '151',
    name: 'MOROCCO',
    iso_code: 'MA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '152',
    name: 'MOZAMBIQUE',
    iso_code: 'MZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '153',
    name: 'MYANMAR',
    iso_code: 'MM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '154',
    name: 'NAMIBIA',
    iso_code: 'NA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '155',
    name: 'NAURU',
    iso_code: 'NR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '156',
    name: 'NEPAL',
    iso_code: 'NP',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '157',
    name: 'NETHERLANDS',
    iso_code: 'NL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '158',
    name: 'NEW CALEDONIA',
    iso_code: 'NC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '159',
    name: 'NEW ZEALAND',
    iso_code: 'NZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '160',
    name: 'NICARAGUA',
    iso_code: 'NI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '161',
    name: 'NIGER',
    iso_code: 'NE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '162',
    name: 'NIGERIA',
    iso_code: 'NG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '163',
    name: 'NIUE',
    iso_code: 'NU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '164',
    name: 'NORFOLK ISLAND',
    iso_code: 'NF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '132',
    name: 'NORTH MACEDONIA',
    iso_code: 'MK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '165',
    name: 'NORTHERN MARIANA ISLANDS',
    iso_code: 'MP',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '166',
    name: 'NORWAY',
    iso_code: 'NO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '167',
    name: 'OMAN',
    iso_code: 'OM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '168',
    name: 'PAKISTAN',
    iso_code: 'PK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '169',
    name: 'PALAU',
    iso_code: 'PW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '170',
    name: 'PALESTINIAN TERRITORY, OCCUPIED',
    iso_code: 'PS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '171',
    name: 'PANAMA',
    iso_code: 'PA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '172',
    name: 'PAPUA NEW GUINEA',
    iso_code: 'PG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '173',
    name: 'PARAGUAY',
    iso_code: 'PY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '174',
    name: 'PERU',
    iso_code: 'PE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '175',
    name: 'PHILIPPINES',
    iso_code: 'PH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '176',
    name: 'PITCAIRN',
    iso_code: 'PN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '177',
    name: 'POLAND',
    iso_code: 'PL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '178',
    name: 'PORTUGAL',
    iso_code: 'PT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '179',
    name: 'PUERTO RICO',
    iso_code: 'PR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '180',
    name: 'QATAR',
    iso_code: 'QA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '181',
    name: 'REUNION',
    iso_code: 'RE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '182',
    name: 'ROMANIA',
    iso_code: 'ROU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '183',
    name: 'RUSSIAN FEDERATION',
    iso_code: 'RU',
    shipping_excluded: '1',
    display_po_box_info: '0',
    billing_excluded: '1',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '184',
    name: 'RWANDA',
    iso_code: 'RW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '185',
    name: 'SAINT BARTHELEMY',
    iso_code: 'BL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '186',
    name: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    iso_code: 'SH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '187',
    name: 'SAINT KITTS AND NEVIS',
    iso_code: 'KN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '188',
    name: 'SAINT LUCIA',
    iso_code: 'LC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '189',
    name: 'SAINT MARTIN (FRENCH PART)',
    iso_code: 'MF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '190',
    name: 'SAINT PIERRE AND MIQUELON',
    iso_code: 'PM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '191',
    name: 'SAINT VINCENT AND THE GRENADINES',
    iso_code: 'VC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '192',
    name: 'SAMOA',
    iso_code: 'WS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '193',
    name: 'SAN MARINO',
    iso_code: 'SM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '194',
    name: 'SAO TOME AND PRINCIPE',
    iso_code: 'ST',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '195',
    name: 'SAUDI ARABIA',
    iso_code: 'SA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '196',
    name: 'SENEGAL',
    iso_code: 'SN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '197',
    name: 'SERBIA',
    iso_code: 'RS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '198',
    name: 'SEYCHELLES',
    iso_code: 'SC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '199',
    name: 'SIERRA LEONE',
    iso_code: 'SL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '200',
    name: 'SINGAPORE',
    iso_code: 'SG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '201',
    name: 'SINT MAARTEN (DUTCH PART)',
    iso_code: 'SX',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '202',
    name: 'SLOVAKIA',
    iso_code: 'SK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '203',
    name: 'SLOVENIA',
    iso_code: 'SI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '204',
    name: 'SOLOMON ISLANDS',
    iso_code: 'SB',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '205',
    name: 'SOMALIA',
    iso_code: 'SO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '206',
    name: 'SOUTH AFRICA',
    iso_code: 'ZA',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '207',
    name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    iso_code: 'GS',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '208',
    name: 'SPAIN',
    iso_code: 'ES',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '209',
    name: 'SRI LANKA',
    iso_code: 'LK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '210',
    name: 'SUDAN',
    iso_code: 'SD',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '211',
    name: 'SURINAME',
    iso_code: 'SR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '212',
    name: 'SVALBARD AND JAN MAYEN',
    iso_code: 'SJ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '214',
    name: 'SWEDEN',
    iso_code: 'SE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '215',
    name: 'SWITZERLAND',
    iso_code: 'CH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '216',
    name: 'SYRIAN ARAB REPUBLIC',
    iso_code: 'SY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '217',
    name: 'TAIWAN, PROVINCE OF CHINA',
    iso_code: 'TW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '218',
    name: 'TAJIKISTAN',
    iso_code: 'TJ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '219',
    name: 'TANZANIA, UNITED REPUBLIC OF',
    iso_code: 'TZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '220',
    name: 'THAILAND',
    iso_code: 'TH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '221',
    name: 'TIMOR-LESTE',
    iso_code: 'TL',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '222',
    name: 'TOGO',
    iso_code: 'TG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '223',
    name: 'TOKELAU',
    iso_code: 'TK',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '224',
    name: 'TONGA',
    iso_code: 'TO',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '225',
    name: 'TRINIDAD AND TOBAGO',
    iso_code: 'TT',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '226',
    name: 'TUNISIA',
    iso_code: 'TN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '227',
    name: 'TURKEY',
    iso_code: 'TR',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '228',
    name: 'TURKMENISTAN',
    iso_code: 'TM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '229',
    name: 'TURKS AND CAICOS ISLANDS',
    iso_code: 'TC',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '230',
    name: 'TUVALU',
    iso_code: 'TV',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '231',
    name: 'UGANDA',
    iso_code: 'UG',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '232',
    name: 'UKRAINE',
    iso_code: 'UA',
    shipping_excluded: '1',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '233',
    name: 'UNITED ARAB EMIRATES',
    iso_code: 'AE',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '234',
    name: 'UNITED KINGDOM',
    iso_code: 'GB',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '1',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '235',
    name: 'UNITED STATES',
    iso_code: 'US',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'USD',
    allow_print_inspection_copy: '0',
    is_usd_region: '1',
    is_usd_domestic: '1',
    po_box_info:
      '<p>Please provide a phone number that you are contactable on and include the full area code. If shipping to a PO box, please include details in full, including PO box number, city, state and zip code.</p>'
  },
  {
    countryid: '236',
    name: 'UNITED STATES MINOR OUTLYING ISLANDS',
    iso_code: 'UM',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '237',
    name: 'URUGUAY',
    iso_code: 'UY',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '238',
    name: 'UZBEKISTAN',
    iso_code: 'UZ',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '239',
    name: 'VANUATU',
    iso_code: 'VU',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '240',
    name: 'VATICAN CITY STATE',
    iso_code: 'see H',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '241',
    name: 'VENEZUELA, BOLIVARIAN REPUBLIC OF',
    iso_code: 'VE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '242',
    name: 'VIETNAM',
    iso_code: 'VN',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '243',
    name: 'VIRGIN ISLANDS, BRITISH',
    iso_code: 'VG',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '244',
    name: 'VIRGIN ISLANDS, U.S.',
    iso_code: 'VI',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '245',
    name: 'WALLIS AND FUTUNA',
    iso_code: 'WF',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '246',
    name: 'WESTERN SAHARA',
    iso_code: 'EH',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '247',
    name: 'YEMEN',
    iso_code: 'YE',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '248',
    name: 'ZAMBIA',
    iso_code: 'ZM',
    shipping_excluded: '0',
    display_po_box_info: '1',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  },
  {
    countryid: '249',
    name: 'ZIMBABWE',
    iso_code: 'ZW',
    shipping_excluded: '0',
    display_po_box_info: '0',
    billing_excluded: '0',
    shipping_currency: 'GBP',
    allow_print_inspection_copy: '0',
    is_usd_region: '0',
    is_usd_domestic: '0',
    po_box_info: null
  }
];

export { countriesList };
