import React from 'react';
import PropTypes from 'prop-types';
import { countriesList } from './constants';

const NewsletterInput = ({ type, formKey, value, placeholder, isValid, handleOnChange }) => (
  <div className="kp-newsletter__wrapper__content__form-container__form__input">
    {type === 'text' && (
      <input
        id={formKey}
        className={isValid ? '' : 'required'}
        type="text"
        name={formKey}
        placeholder={placeholder}
        value={value}
        onChange={({ target: { value: eValue } }) => handleOnChange(eValue)}
      />
    )}
    {type === 'select' && (
      <select
        id={formKey}
        className={isValid ? '' : 'required'}
        name={formKey}
        value={value}
        onChange={({ target: { value: eValue } }) => handleOnChange(eValue)}>
        <option value="">Select your country</option>
        {countriesList.map(({ countryid, name: countryName }) => (
          <option key={countryid} value={`${countryid}/${countryName}`}>
            {countryName}
          </option>
        ))}
      </select>
    )}

    {!isValid && <p className="required-message">Required</p>}
  </div>
);

NewsletterInput.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  formKey: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isValid: PropTypes.bool
};

NewsletterInput.defaultProps = {
  type: '',
  formKey: '',
  value: '',
  placeholder: '',
  isValid: true
};

export default NewsletterInput;
