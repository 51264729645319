const validateEmail = email => !!/(.+)@(.+){2,}\.(.+){2,}/.test(email);

const validateFields = formFields => {
  let isFormValid = true;
  const validatedFields = {};
  Object.keys(formFields).forEach(fieldKey => {
    const currentField = formFields[fieldKey];
    const isFieldValid = fieldKey === 'email' ? validateEmail(currentField) : !!currentField;
    if (!isFieldValid) isFormValid = false;
    validatedFields[fieldKey] = isFieldValid;
  });

  return [validatedFields, isFormValid];
};

const postNewsletterData = async (data, setIsSubscribed, setErrorMessage) => {
  const baseUrl = new URL(process.env.BLAZE_WSB_API_BASE_URL);
  if (!baseUrl) return;

  const { username, password } = baseUrl;

  const headers = {
    'Content-Type': 'application/json',
    'X-csrf-token': process.env.BLAZE_SECURE_HEADER || ''
  };
  if (username && password) {
    baseUrl.username = '';
    baseUrl.password = '';
    headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
  }

  const baseUrlString = baseUrl.toString();
  const endpoint = !baseUrlString.includes('content/component')
    ? `${baseUrlString}content/component`
    : baseUrlString;

  await fetch(endpoint, {
    method: 'POST',
    headers,
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(res => {
      const { error_code: errCode, messages } = res;
      if (errCode && messages) setErrorMessage(messages.validation[0]);
      else {
        window.localStorage.setItem('newsletter_subscribed', true);
        setErrorMessage(null);
        setIsSubscribed(true);
      }
    });
};

export { validateEmail, validateFields, postNewsletterData };
