import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from '@blaze-cms/nextjs-components';
import { validateFields, postNewsletterData } from './helpers';
import { PRIVACY_POLICY_TEXT } from '../constants';
import NewsletterInput from './NewsletterInput';

const Newsletter = ({
  modifier,
  privacyUrl,
  newsletterSignedUpText,
  style,
  privacyPolicyText,
  title
}) => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [showSignedUpText, setShowSignedUpText] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');

  const [validFormFields, setValidFormFields] = useState({
    name: true,
    email: true,
    country: true
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localIsSubscribed = !!window.localStorage.getItem('newsletter_subscribed');
      setIsSubscribed(localIsSubscribed);
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const [validatedFields, isFormValid] = validateFields({ name, email, country });
    setValidFormFields(validatedFields);
    if (!isFormValid) return setErrorMessage(null);
    const [countryId, countryName] = country.split('/');
    const parsedData = {
      component_name: 'newsletter',
      component_params: {
        name,
        email,
        country_name: countryName,
        countryid: countryId
      }
    };
    postNewsletterData(parsedData, setShowSignedUpText, setErrorMessage);
  };

  if (isSubscribed) return null;

  const isVertical = style === 'vertical';

  const newsletterClassname = classnames('kp-newsletter', {
    'kp-newsletter--vertical': isVertical,
    [`kp-newsletter--${modifier}`]: modifier
  });

  return (
    <div className={newsletterClassname}>
      <div className="kp-newsletter__wrapper">
        <div className="kp-newsletter__wrapper__content">
          {!showSignedUpText && (
            <div className="kp-newsletter__wrapper__content__not-subscribed">
              <div className="kp-newsletter__wrapper__content__text">
                <h2>{title}</h2>
              </div>
              <div className="kp-newsletter__wrapper__content__form-container">
                <form
                  className="kp-newsletter__wrapper__content__form-container__form"
                  onSubmit={handleSubmit}>
                  <NewsletterInput
                    type="text"
                    formKey="name"
                    placeholder="Your name"
                    value={name}
                    isValid={validFormFields.name}
                    handleOnChange={setName}
                  />
                  <NewsletterInput
                    type="text"
                    formKey="email"
                    placeholder="Your email"
                    value={email}
                    isValid={validFormFields.email}
                    handleOnChange={setEmail}
                  />
                  <NewsletterInput
                    type="select"
                    formKey="country"
                    value={country}
                    isValid={validFormFields.country}
                    handleOnChange={setCountry}
                  />

                  <div className="kp-newsletter__wrapper__content__form-container__form__button">
                    <button type="submit" className="button button--submit">
                      Sign up
                    </button>
                  </div>
                </form>
              </div>

              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
          )}

          {showSignedUpText && (
            <div className="kp-newsletter__wrapper__content__subscribed-message">
              {newsletterSignedUpText}
            </div>
          )}
          <div className="kp-newsletter__wrapper__content__privacy">
            {privacyPolicyText || PRIVACY_POLICY_TEXT}&nbsp;
            <Link href={privacyUrl} className="kp-newsletter__wrapper__content__privacy__link">
              privacy policy
            </Link>
          </div>
        </div>
        <div className="kp-newsletter__wrapper__logo-container">
          <div className="kp-newsletter__wrapper__logo-container__logo" />
        </div>
      </div>
    </div>
  );
};

Newsletter.propTypes = {
  modifier: PropTypes.string,
  title: PropTypes.string,
  privacyPolicyText: PropTypes.string,
  style: PropTypes.string,
  privacyUrl: PropTypes.string,
  newsletterSignedUpText: PropTypes.string
};

Newsletter.defaultProps = {
  modifier: '',
  title: '',
  privacyPolicyText: '',
  style: 'default',
  privacyUrl: '',
  newsletterSignedUpText: ''
};

export default Newsletter;
